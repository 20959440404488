'use client'

import IconCupChampionWin from '@haiper/icons-svg/icons/solid/cup-champion-win.svg'
import usePromptIdeas from '@/hooks/usePromptIdeas'
import { cls, whisper } from '@/utils'
import { nanoid } from 'nanoid'
import { useCallback, useState } from 'react'
import IconRefresh from '@haiper/icons-svg/icons/outline/refresh-cw.svg'
import { useSetAtom } from 'jotai'
import { promptAtom, negativePromptAtom } from '@/atoms'
import { useRouter } from 'next/navigation'

export interface PromptIdeasProps {
  className?: string
}

export default function PromptIdeas({ className }: PromptIdeasProps) {
  const [refreshKey, setRefreshKey] = useState(nanoid())
  const { data: ideas, loading } = usePromptIdeas(refreshKey)
  const setPrompt = useSetAtom(promptAtom)
  const setNegativePrompt = useSetAtom(negativePromptAtom)
  const router = useRouter()

  const handleRefresh = useCallback(() => {
    setRefreshKey(nanoid())
  }, [])

  const handleEventClick = useCallback(
    (event?: haiper.MarketingEvent | null) => {
      if (!event) {
        return
      }
      router.push(`/event?evt=${event.event_id}`)
    },
    [router],
  )

  const handleIdeaClick = useCallback(
    (idea: haiper.PromptSuggestion) => {
      setPrompt(idea.prompt ?? '')
      setNegativePrompt(idea.negative_prompt ?? '')
    },
    [setPrompt, setNegativePrompt],
  )

  return (
    <div
      className={cls(
        'h-26 md:h-7 flex flex-col md:flex-row items-center gap-2 md:gap-3 overflow-hidden text-body-md tracking-15',
        className,
      )}
    >
      <div className='w-full md:w-auto md:h-full flex items-center' aria-label='label'>
        <div className='size-7 shrink-0 flex items-center justify-center' onClick={handleRefresh}>
          <IconRefresh className='size-5 text-icon cursor-pointer hover:opacity-80' />
        </div>
        <span>Ideas:</span>
      </div>
      <div className='flex items-center gap-3 flex-wrap h-17 md:h-7 overflow-hidden' aria-label='prompts'>
        {ideas?.map((idea, index) => {
          return (
            <div
              key={[idea.label, idea.prompt, idea.negative_prompt, index].join()}
              className={cls(
                'px-2 py-1 h-7 border rounded-md flex items-center cursor-pointer hover:bg-surface-hover overflow-hidden',
                idea.event && 'cursor-default hover:bg-surface p-0',
              )}
            >
              <div className={cls('group flex items-center', idea.event && 'h-full')}>
                {idea.event && (
                  <div
                    className='text-primary tracking-15 text-body-sm leading-5 font-bold hover:bg-surface-hover pl-2 pr-1 h-full cursor-pointer flex items-center justify-center gap-0.5'
                    onClick={() => handleEventClick(idea.event)}
                  >
                    <IconCupChampionWin className='size-3 shrink-0 grow-0' />
                    {idea.event?.title}
                  </div>
                )}
                {idea.event && <div className='w-px h-3 bg-border group-hover:h-full' />}
                <div
                  className={cls(
                    'h-full hover:bg-surface-hover cursor-pointer flex items-center justify-center',
                    idea.event && 'pl-1 pr-2',
                  )}
                  onClick={() => handleIdeaClick(idea)}
                >
                  {idea.label}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
