import { useMemo } from 'react'
import useCreationSettings from './useCreationSettings'
import useOngoingEvents from './useOngoingEvents'
import { flatMap, sample, shuffle } from 'lodash-es'
import { whisper } from '@/utils'

interface PromptIdea extends haiper.PromptSuggestion {
  event?: haiper.MarketingEvent
}

export default function usePromptIdeas(refreshKey?: string): { data: PromptIdea[]; loading: boolean } {
  const { data: creationSettingsConfig, isValidating: settingsLoading } = useCreationSettings()
  const { data: ongoingEvents, isValidating: eventsLoading } = useOngoingEvents()

  const loading = settingsLoading || eventsLoading

  const eventIdeas: PromptIdea[] = useMemo(() => {
    return flatMap(shuffle(ongoingEvents), (event) => {
      if (!event.prompts?.length || !refreshKey) return []

      // pick a random prompt from every event
      const randomItem: haiper.PromptSuggestion = sample(event.prompts) as haiper.PromptSuggestion
      return {
        ...randomItem,
        event,
      }
    })
  }, [ongoingEvents, refreshKey])

  const configIdeas = useMemo(() => {
    if (!refreshKey) return []

    const arr = creationSettingsConfig?.ideas ?? []
    return shuffle(arr)
  }, [creationSettingsConfig, refreshKey])

  const data: PromptIdea[] = useMemo(() => {
    return [...eventIdeas, ...configIdeas]
  }, [configIdeas, eventIdeas])

  return {
    data,
    loading,
  }
}
